<template>
  <div>
    <b-row>
      <b-col v-if="mustShowWelcomeCard">
        <b-card
          text-variant="center"
          class="card card-congratulations"
        >
          <!-- images -->
          <b-img
            :src="require('@/assets/images/elements/decore-left.png')"
            class="congratulations-img-left"
          />
          <b-img
            :src="require('@/assets/images/elements/decore-right.png')"
            class="congratulations-img-right"
          />
          <!--/ images -->

          <b-avatar
            variant="primary"
            size="70"
            class="shadow mb-2"
          >
            <feather-icon
              size="28"
              icon="AwardIcon"
            />
          </b-avatar>
          <div style="margin-top: -60px; font-weight: 500; font-size: 14px;">
            <h3 class="mb-1 mt-5 text-white">
              {{ $t('Home.welcomeCard.msg', { name: getFirstName }) }}
            </h3>
            <div style="margin-top: -40px; padding: 0 0 30px;">
              <h3 class="mb-1 mt-5 text-white">
                {{ $t('Home.welcomeCard.subtitle') }}
              </h3>
              <p>
                {{ $t('Home.welcomeCard.questionTour') }}
              </p>
              <div style="margin-top: 40px;">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  style="width: 413px; max-width: 100%; background: #FF9F43; color: #fff; padding: 10px 22px; border-radius: 5px; margin-right: 8px;"
                  @click="tourStart"
                >
                  {{ $t('Home.welcomeCard.startTourButton') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-light"
                  @click="setReceiveWelcome"
                >
                  {{ $t('Home.welcomeCard.button') }}
                </b-button>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <app-tour :steps="steps" />
  </div>
</template>

<script>
import {
  BCard, BButton, BImg, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { getUserData, userReceiveWelcome, setUserReceiveWelcome } from '@/auth/utils'
import AppTour from '@core/components/app-tour/AppTour.vue'

export default {
  components: {
    BCard,
    BImg,
    BButton,
    BAvatar,
    BRow,
    BCol,
    AppTour,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      welcomeClicked: false,
      userData: getUserData(),
    }
  },
  computed: {
    receiveWelcome() { return userReceiveWelcome() },
    getFirstName() { return this.userData.name.split(' ')[0] },
    mustShowWelcomeCard() { return !(this.receiveWelcome || this.welcomeClicked) },
    steps() {
      return [
        {
          target: '#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li.nav-item.active > a > span',
          ...this.$t('tour.home'),
        },
        {
          target: '#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(3) > a > span',
          ...this.$t('tour.profile'),
        },
        {
          target: '#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(4) > a > span',
          ...this.$t('tour.areaTypeLimits'),
        },
        {
          target: '#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(5) > a > span',
          ...this.$t('tour.barriers'),
        },
        {
          target: '#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(6) > a > span',
          ...this.$t('tour.reducingDeciLayers'),
        },
        {
          target: '#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(7) > a > span',
          ...this.$t('tour.assets'),
        },
        {
          target: '#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(8) > a > span',
          ...this.$t('tour.calcParams'),
        },
        {
          target: '#app > div > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light > ul > ul > li:nth-child(9) > a > span',
          ...this.$t('tour.projects'),
        },
      ]
    },
  },
  created() { },
  methods: {
    kFormatter,
    setReceiveWelcome() {
      this.welcomeClicked = true
      setUserReceiveWelcome()
    },
    tourStart() {
      this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
      this.$tours.vuexyTour.start()
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';

.pending-company-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>
